.about {
  background-color: #0a192f;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}
.about_shadow {
  position: absolute;
  top: 8vh;
  height: 92vh;
  width: 100%;
  z-index: -20;
}

.container {
  z-index: 2;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  top: 5rem;
  left: 0;
}
.subcontainer {
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  width: 25vw;
  border-radius: 50rem;
}
.subheading {
  position: relative;
  bottom: 4rem;
  color: #e57928;
  font-size: 3rem;
  font-weight: 400;
}
.subcontainer p {
  padding: 0 4.5vw;
  font-size: 1.1rem;
  line-height: 2.5rem;
  letter-spacing: 0.08rem;
}

@media (max-width: 700px) {
  .about_shadow {
    height: 100vh;
    top: 2vh;
  }
  .container {
    flex-direction: column-reverse;
    justify-content: center;
    height: 100%;
  }
  .subcontainer {
    width: 100%;
    padding: 3rem 1rem 0 1rem;
  }
  .subheading {
    position: absolute;
    top: 3rem;
    font-size: 2.5rem;
  }
  .subcontainer p {
    font-weight: 300;
    line-height: 2.2rem;
  }
  .image {
    width: 50vw;
  }
}
