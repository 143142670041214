* {
  text-decoration: none;
  color: #fff;
  list-style: none;
  padding: 0;
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(17, 17, 17, 0.85);
  position: fixed;
  color: #fff;
  font-size: 1rem;
  z-index: 30;
}
.nav {
  width: 100%;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: flex-end;
}
.nav__item {
  width: 100px;
  text-align: center;
  box-sizing: content-box;
}
.nav__item a {
  padding-bottom: 0.4rem;
  width: 100%;
  cursor: pointer;
}
.nav__item .active {
  border-bottom: solid 0.2rem #e57928;
}

@media (min-width: 800px) {
  .nav__item a:hover {
    border-bottom: solid 0.2rem #e57928;
    z-index: 4;
  }
}
