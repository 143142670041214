.footnote {
  height: 20vh;
  /* width: 100%; */
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.2rem;
  text-align: center;
}

@media (max-width: 800px) {
  .footnote {
    padding-top: 3rem;
    font-size: 1.5rem;
    font-weight: 300;
  }
}