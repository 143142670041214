.projects {
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0A192F;
  position: relative;
}
.projects_shadow {
  position: absolute;
  top: 0;
  height: calc(100% + 10vh);
  width: 100%;
  z-index: -20;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 3;
}
.subheading {
  color: #e57928;
  font-size: 3rem;
  font-weight: 400;
}

@media (max-width: 700px) {
  .projects_shadow {
    top: 2vh;
    height: calc(100% + 8vh);
  }
  .subheading {
    font-size: 2.5rem;
    padding-bottom: 2rem;
  }
}