.contact {
  background-color: #0a192f;
  display: flex;
  justify-content: center;
  position: relative;
  height: 110vh;
  z-index: 5;
}
.contact::after {
  content: "";
  background-color: #111;
  height: 120vh;
  width: 100%;
  position: absolute;
  transform: skewY(-5deg);
  left: 0;
  z-index: 2;
}

.contact_shadow {
  position: absolute;
  top: calc(100% - 100vh);
  height: calc(100vh);
  width: 100%;
  z-index: -20;
}

.container {
  position: relative;
  top: 30%;
  z-index: 3;
  font-weight: 200;
}
.subheading {
  color: #e57928;
  text-align: center;
  font-weight: 400;
  font-size: 3rem;
}

.container__content {
  margin: 7rem auto 0 auto;
  width: 80%;
}
.footnote {
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
}
.email {
  margin-top: 4rem;
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
}

.socials {
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.icon_github {
  margin-right: 3rem;
  height: 3rem;
  cursor: pointer;
}
.icon_github:hover > path {
  color: #e57928;
}
.icon_linkedin {
  height: 3rem;
  cursor: pointer;
}
.icon_linkedin:hover > path {
  color: #e57928;
}

@media (max-width: 800px) {
  .email {
    font-size: 1.8rem;
  }
}
